import React from 'react';
import { FeatureFlagsProvider } from '@features/feature-flags';

type RootElementProps = {
  children: React.ReactNode;
}
const RootElement = ({ children }: RootElementProps) => {
  return(
    <FeatureFlagsProvider>
      {children}
    </FeatureFlagsProvider>
  );
};

export {
  RootElement
}
