import React from 'react';

import './src/styles/global.css';
import './src/styles/input.css';
import './src/styles/markdown.css';
import { GatsbyBrowser } from 'gatsby';
import { RootElement } from '@features/root';

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = (
  { element }: { element: any }) => (
  <RootElement>
    {element}
  </RootElement>
);
