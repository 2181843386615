import { Flag } from '@features/feature-flags/flag';

type EnvironmentFlags = {
  development: Flag[],
  production: Flag[]
}
const FLAGS: EnvironmentFlags = {
  development: [
    { name: 'blog', isActive: true }
  ],
  production: [
    { name: 'blog', isActive: false }
  ],
}

export {
  FLAGS,
  type EnvironmentFlags
}
