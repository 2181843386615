/**
 * Gets environment variables configured at build.
 */
const useEnvVars = () => {
  return {
    GATSBY_WEB3FORMS_ACCESS_KEY: process.env.GATSBY_WEB3FORMS_ACCESS_KEY,
    NODE_ENV: process.env.NODE_ENV
  };
};

export {
  useEnvVars
}
