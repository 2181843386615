import React, { createContext, useCallback, useContext, useState } from 'react';
import { useEnvVars } from '@features/environment';
import { FLAGS } from '@features/feature-flags/environmentFlags';
import { Flag } from '@features/feature-flags/flag';

type FeatureFlagsContextType = {
  isFeatureEnabled: (featureName: string) => boolean
  features: Flag[]
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType | undefined>(undefined);

const useFeatureFlagsContext = (): FeatureFlagsContextType => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error('useFeatureFlagsContext must be used inside the FeatureFlagsProvider');
  }

  return context;
}

type FeatureFlagsProviderProps = {
  children: React.ReactNode,
}

const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const { NODE_ENV } = useEnvVars();

  const [ features ] = useState(NODE_ENV?.toLowerCase() == 'development' ?
    FLAGS['development'] :
    FLAGS['production']
  );

  const isFeatureEnabled = useCallback((featureName: string) => {
    return features.find(it => it.name === featureName)?.isActive ?? false;
  }, [features]);

  const value = {
    features,
    isFeatureEnabled
  };

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export {
  FeatureFlagsProvider,
  FeatureFlagsContext,
  useFeatureFlagsContext,
  type FeatureFlagsProviderProps,
}
